// Hotwire
import "@hotwired/turbo-rails"

import "./controllers"

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

// Get Bootstrap's JavaScript to work
import * as bootstrap from "bootstrap"

// Styles
import "~/styles/_custom.scss"
import "~/styles/application.css"

import "@github/relative-time-element"
